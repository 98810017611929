'use client';

import { notify } from '@voyage-lab/ui';
import { parseAsString, useQueryStates } from 'nuqs';
import { useEffect } from 'react';

export const ToastMessageListener = () => {
	const [searchParams] = useQueryStates({
		error: parseAsString,
		success: parseAsString,
	});

	useEffect(() => {
		if (searchParams.error) {
			notify.error(searchParams.error);
		}
		if (searchParams.success) {
			notify.success(searchParams.success);
		}
		// return () => notify.remove(); // It closes all notification immediately
	}, [searchParams]);

	return null;
};
