import type { PostgrestClientType } from '@voyage-lab/db';
import type {
	Nullable,
	TrackerClientConfig,
	TrackerEvent,
	TrackerEventIdentity,
	TrackingEventData,
} from './client.types';
import { v4 as uuid } from 'uuid';
import { Schema } from './client.schema';

export class TrackerClient {
	#isClient = typeof window !== 'undefined';
	#identity: Nullable<Partial<TrackerEventIdentity>>;
	#trackedEventIds: Set<string> = new Set<string>();

	#dbClient?: PostgrestClientType;
	#config: TrackerClientConfig;

	constructor(config: TrackerClientConfig) {
		this.#config = config;
		this.#dbClient = config.dbClient;
	}

	async event<EventName extends keyof TrackingEventData>(event: TrackerEvent<EventName>) {
		// Discard event if it was already tracked

		// Attach a unique id to the event
		if (!event.id) event.id = uuid();
		event.identity = this.#identity;

		// Push event to data layer if it exists
		if (this.#isClient) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const dataLayer = (window as any).dataLayer || [];
			if (this.#trackedEventIds.has(event.id)) return console.warn('Event already tracked', event.id);
			dataLayer.push(event);
		}

		// Send event to all providers
		const providerRequest = this.#config.providers.map((provider) => provider.event(event));
		await Promise.all(providerRequest);

		this.#trackedEventIds.add(event.id);
	}

	async identify(identity: Nullable<Partial<TrackerEventIdentity>>) {
		if (this.#identity && this.#identity.email === identity.email && this.#identity.id === identity.id) {
			console.warn('Identify called with the same identity');
			return;
		}

		const email = identity.email ? this.formatEmail(identity.email) : null;

		if (!email) {
			console.warn('Invalid email provided to identify');
			return;
		}

		if (this.#dbClient) {
			// Find the user on database
			const identityQuery = this.#dbClient.from('users').select('*,brands!inner(*)');

			if (identity.id) {
				identityQuery.eq('id', identity.id);
			} else {
				identityQuery.eq('email', email);
			}

			const user = await identityQuery.limit(1).limit(1, { foreignTable: 'brands' }).maybeSingle();

			if (!user) {
				console.warn('User not found on database');
			}
		}

		const parsedIdentity = Schema.TrackingEventIdentity.safeParse(identity);
		if (parsedIdentity.success) {
			this.#identity = { ...parsedIdentity.data, email };
		}

		// Send identify to all providers
		const providerRequest = this.#config.providers.map((provider) => provider.identify(this.#identity));
		await Promise.all(providerRequest);
	}

	private formatEmail(email: string) {
		const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
		return isValidEmail ? email.toLowerCase() : null;
	}
}
