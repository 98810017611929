import(/* webpackMode: "eager" */ "/home/runner/work/vyg/vyg/apps/web-client/style/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmModalProvider"] */ "/home/runner/work/vyg/vyg/libs/ui/components/confirm/confirm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/auth/context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastMessageListener"] */ "/home/runner/work/vyg/vyg/libs/web/src/components/toast/listener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryClientProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/react-query.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["defaultTheme"] */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingProvider"] */ "/home/runner/work/vyg/vyg/libs/web/src/contexts/tracking.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vyg/vyg/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vyg/vyg/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vyg/vyg/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vyg/vyg/node_modules/next/dist/client/script.js");
