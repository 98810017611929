import { z } from 'zod';
const TrackingEventIdentity = z.object({
	anonymous_id: z.string().nullish(),
	email: z.string().nullish(),
	id: z.string().nullish(),
	company_id: z.string().nullish(),
	brand_id: z.string().nullish(),
	company_name: z.string().nullish(),
	first_name: z.string().nullish(),
	last_name: z.string().nullish(),
});

export const Schema = {
	TrackingEventIdentity,
};
