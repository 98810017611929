'use client';

import { TrackerClient } from '@voyage-lab/core-tracking';
import { FC, createContext, useContext } from 'react';

export const TrackingContext = createContext<TrackerClient | null>(null);

export const TrackingProvider = ({ children }: { children: React.ReactNode }) => {
	const tracker = new TrackerClient({
		providers: [],
	});

	return <TrackingContext.Provider value={tracker}>{children}</TrackingContext.Provider>;
};

export const useTracking = () => {
	const tracking = useContext(TrackingContext);

	if (!tracking) {
		throw new Error('useTracking must be used within a TrackingProvider');
	}

	return tracking;
};
